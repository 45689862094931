import { Union } from "../fable-library-js.4.24.0/Types.js";
import { tuple_type, char_type, int32_type, class_type, list_type, option_type, string_type, union_type } from "../fable-library-js.4.24.0/Reflection.js";
import { MarkdownRange_$reflection } from "./Range.fs.js";
import { tail, head, empty, reverse, length, cons, concat, singleton, isEmpty } from "../fable-library-js.4.24.0/List.js";
import { FSharpChoice$3, FSharpChoice$2 } from "../fable-library-js.4.24.0/Choice.js";

export class MarkdownListKind extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ordered", "Unordered"];
    }
}

export function MarkdownListKind_$reflection() {
    return union_type("Fable.Formatting.Markdown.MarkdownListKind", [], MarkdownListKind, () => [[], []]);
}

export class MarkdownColumnAlignment extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AlignLeft", "AlignRight", "AlignCenter", "AlignDefault"];
    }
}

export function MarkdownColumnAlignment_$reflection() {
    return union_type("Fable.Formatting.Markdown.MarkdownColumnAlignment", [], MarkdownColumnAlignment, () => [[], [], [], []]);
}

export class MarkdownSpan extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Literal", "InlineCode", "Strong", "Emphasis", "AnchorLink", "DirectLink", "IndirectLink", "DirectImage", "IndirectImage", "HardLineBreak", "LatexInlineMath", "LatexDisplayMath", "EmbedSpans"];
    }
}

export function MarkdownSpan_$reflection() {
    return union_type("Fable.Formatting.Markdown.MarkdownSpan", [], MarkdownSpan, () => [[["text", string_type], ["range", option_type(MarkdownRange_$reflection())]], [["code", string_type], ["range", option_type(MarkdownRange_$reflection())]], [["body", list_type(MarkdownSpan_$reflection())], ["range", option_type(MarkdownRange_$reflection())]], [["body", list_type(MarkdownSpan_$reflection())], ["range", option_type(MarkdownRange_$reflection())]], [["link", string_type], ["range", option_type(MarkdownRange_$reflection())]], [["body", list_type(MarkdownSpan_$reflection())], ["link", string_type], ["title", option_type(string_type)], ["range", option_type(MarkdownRange_$reflection())]], [["body", list_type(MarkdownSpan_$reflection())], ["original", string_type], ["key", string_type], ["range", option_type(MarkdownRange_$reflection())]], [["body", string_type], ["link", string_type], ["title", option_type(string_type)], ["range", option_type(MarkdownRange_$reflection())]], [["body", string_type], ["link", string_type], ["key", string_type], ["range", option_type(MarkdownRange_$reflection())]], [["range", option_type(MarkdownRange_$reflection())]], [["code", string_type], ["range", option_type(MarkdownRange_$reflection())]], [["code", string_type], ["range", option_type(MarkdownRange_$reflection())]], [["customSpans", class_type("Fable.Formatting.Markdown.MarkdownEmbedSpans")], ["range", option_type(MarkdownRange_$reflection())]]]);
}

export class MarkdownParagraph extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Heading", "Paragraph", "CodeBlock", "InlineHtmlBlock", "ListBlock", "QuotedBlock", "Span", "LatexBlock", "HorizontalRule", "TableBlock", "OtherBlock", "EmbedParagraphs", "YamlFrontmatter", "OutputBlock"];
    }
}

export function MarkdownParagraph_$reflection() {
    return union_type("Fable.Formatting.Markdown.MarkdownParagraph", [], MarkdownParagraph, () => [[["size", int32_type], ["body", list_type(MarkdownSpan_$reflection())], ["range", option_type(MarkdownRange_$reflection())]], [["body", list_type(MarkdownSpan_$reflection())], ["range", option_type(MarkdownRange_$reflection())]], [["code", string_type], ["executionCount", option_type(int32_type)], ["language", string_type], ["ignoredLine", string_type], ["range", option_type(MarkdownRange_$reflection())]], [["code", string_type], ["executionCount", option_type(int32_type)], ["range", option_type(MarkdownRange_$reflection())]], [["kind", MarkdownListKind_$reflection()], ["items", list_type(list_type(MarkdownParagraph_$reflection()))], ["range", option_type(MarkdownRange_$reflection())]], [["paragraphs", list_type(MarkdownParagraph_$reflection())], ["range", option_type(MarkdownRange_$reflection())]], [["body", list_type(MarkdownSpan_$reflection())], ["range", option_type(MarkdownRange_$reflection())]], [["env", string_type], ["body", list_type(string_type)], ["range", option_type(MarkdownRange_$reflection())]], [["character", char_type], ["range", option_type(MarkdownRange_$reflection())]], [["headers", option_type(list_type(list_type(MarkdownParagraph_$reflection())))], ["alignments", list_type(MarkdownColumnAlignment_$reflection())], ["rows", list_type(list_type(list_type(MarkdownParagraph_$reflection())))], ["range", option_type(MarkdownRange_$reflection())]], [["lines", list_type(tuple_type(string_type, MarkdownRange_$reflection()))], ["range", option_type(MarkdownRange_$reflection())]], [["customParagraphs", class_type("Fable.Formatting.Markdown.MarkdownEmbedParagraphs")], ["range", option_type(MarkdownRange_$reflection())]], [["yaml", list_type(string_type)], ["range", option_type(MarkdownRange_$reflection())]], [["output", string_type], ["kind", string_type], ["executionCount", option_type(int32_type)]]]);
}

export function Dsl_$0023(value) {
    return new MarkdownParagraph(0, [1, value, undefined]);
}

export function Dsl_$0023$0023(value) {
    return new MarkdownParagraph(0, [2, value, undefined]);
}

export function Dsl_$0023$0023$0023(value) {
    return new MarkdownParagraph(0, [3, value, undefined]);
}

export function Dsl_$0023$0023$0023$0023(value) {
    return new MarkdownParagraph(0, [4, value, undefined]);
}

export function Dsl_$0023$0023$0023$0023$0023(value) {
    return new MarkdownParagraph(0, [5, value, undefined]);
}

export function Dsl_strong(value) {
    return new MarkdownSpan(2, [value, undefined]);
}

export function Dsl_p(value) {
    return new MarkdownParagraph(1, [value, undefined]);
}

export function Dsl_span(value) {
    return new MarkdownParagraph(6, [value, undefined]);
}

export function Dsl_op_BangBang(value) {
    return new MarkdownSpan(0, [value, undefined]);
}

export function Dsl_link(content, url) {
    return new MarkdownSpan(5, [content, url, undefined, undefined]);
}

export function Dsl_ul(value) {
    return new MarkdownParagraph(4, [new MarkdownListKind(1, []), value, undefined]);
}

export function Dsl_ol(value) {
    return new MarkdownParagraph(4, [new MarkdownListKind(0, []), value, undefined]);
}

export function Dsl_table(headers, alignments, rows) {
    return new MarkdownParagraph(9, [isEmpty(headers) ? undefined : headers, alignments, rows, undefined]);
}

export function Dsl_img(body, link) {
    return new MarkdownSpan(7, [body, link, undefined, undefined]);
}

export class MarkdownPatterns_SpanLeafInfo extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["SL"];
    }
}

export function MarkdownPatterns_SpanLeafInfo_$reflection() {
    return union_type("Fable.Formatting.Markdown.MarkdownPatterns.SpanLeafInfo", [], MarkdownPatterns_SpanLeafInfo, () => [[["Item", MarkdownSpan_$reflection()]]]);
}

export class MarkdownPatterns_SpanNodeInfo extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["SN"];
    }
}

export function MarkdownPatterns_SpanNodeInfo_$reflection() {
    return union_type("Fable.Formatting.Markdown.MarkdownPatterns.SpanNodeInfo", [], MarkdownPatterns_SpanNodeInfo, () => [[["Item", MarkdownSpan_$reflection()]]]);
}

export class MarkdownPatterns_ParagraphSpansInfo extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["PS"];
    }
}

export function MarkdownPatterns_ParagraphSpansInfo_$reflection() {
    return union_type("Fable.Formatting.Markdown.MarkdownPatterns.ParagraphSpansInfo", [], MarkdownPatterns_ParagraphSpansInfo, () => [[["Item", MarkdownParagraph_$reflection()]]]);
}

export class MarkdownPatterns_ParagraphLeafInfo extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["PL"];
    }
}

export function MarkdownPatterns_ParagraphLeafInfo_$reflection() {
    return union_type("Fable.Formatting.Markdown.MarkdownPatterns.ParagraphLeafInfo", [], MarkdownPatterns_ParagraphLeafInfo, () => [[["Item", MarkdownParagraph_$reflection()]]]);
}

export class MarkdownPatterns_ParagraphNestedInfo extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["PN"];
    }
}

export function MarkdownPatterns_ParagraphNestedInfo_$reflection() {
    return union_type("Fable.Formatting.Markdown.MarkdownPatterns.ParagraphNestedInfo", [], MarkdownPatterns_ParagraphNestedInfo, () => [[["Item", MarkdownParagraph_$reflection()]]]);
}

export function MarkdownPatterns_$007CSpanLeaf$007CSpanNode$007C(span) {
    let matchResult, spans;
    switch (span.tag) {
        case 2: {
            matchResult = 1;
            spans = span.fields[0];
            break;
        }
        case 3: {
            matchResult = 1;
            spans = span.fields[0];
            break;
        }
        case 5: {
            matchResult = 1;
            spans = span.fields[0];
            break;
        }
        case 6: {
            matchResult = 1;
            spans = span.fields[0];
            break;
        }
        default:
            matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return new FSharpChoice$2(0, [new MarkdownPatterns_SpanLeafInfo(span)]);
        default:
            return new FSharpChoice$2(1, [[new MarkdownPatterns_SpanNodeInfo(span), spans]]);
    }
}

export function MarkdownPatterns_SpanLeaf(_arg) {
    return _arg.fields[0];
}

export function MarkdownPatterns_SpanNode(_arg, spans) {
    const span = _arg.fields[0];
    switch (span.tag) {
        case 2:
            return new MarkdownSpan(2, [spans, span.fields[1]]);
        case 3:
            return new MarkdownSpan(3, [spans, span.fields[1]]);
        case 5:
            return new MarkdownSpan(5, [spans, span.fields[1], span.fields[2], span.fields[3]]);
        case 6:
            return new MarkdownSpan(6, [spans, span.fields[1], span.fields[2], span.fields[3]]);
        default:
            throw new Error("Incorrect SpanNodeInfo\\nParameter name: ");
    }
}

export function MarkdownPatterns_$007CParagraphLeaf$007CParagraphNested$007CParagraphSpans$007C(par) {
    let matchResult, spans;
    switch (par.tag) {
        case 10:
        case 13:
        case 2:
        case 3:
        case 11:
        case 7:
        case 12:
        case 8: {
            matchResult = 1;
            break;
        }
        case 4: {
            matchResult = 2;
            break;
        }
        case 5: {
            matchResult = 3;
            break;
        }
        case 9: {
            matchResult = 4;
            break;
        }
        case 1: {
            matchResult = 0;
            spans = par.fields[0];
            break;
        }
        case 6: {
            matchResult = 0;
            spans = par.fields[0];
            break;
        }
        default: {
            matchResult = 0;
            spans = par.fields[1];
        }
    }
    switch (matchResult) {
        case 0:
            return new FSharpChoice$3(2, [[new MarkdownPatterns_ParagraphSpansInfo(par), spans]]);
        case 1:
            return new FSharpChoice$3(0, [new MarkdownPatterns_ParagraphLeafInfo(par)]);
        case 2:
            return new FSharpChoice$3(1, [[new MarkdownPatterns_ParagraphNestedInfo(par), par.fields[1]]]);
        case 3:
            return new FSharpChoice$3(1, [[new MarkdownPatterns_ParagraphNestedInfo(par), singleton(par.fields[0])]]);
        default: {
            const rows = par.fields[2];
            const headers = par.fields[0];
            if (headers != null) {
                return new FSharpChoice$3(1, [[new MarkdownPatterns_ParagraphNestedInfo(par), concat(cons(headers, rows))]]);
            }
            else {
                return new FSharpChoice$3(1, [[new MarkdownPatterns_ParagraphNestedInfo(par), concat(rows)]]);
            }
        }
    }
}

export function MarkdownPatterns_ParagraphSpans(_arg, spans) {
    const par = _arg.fields[0];
    switch (par.tag) {
        case 0:
            return new MarkdownParagraph(0, [par.fields[0], spans, par.fields[2]]);
        case 1:
            return new MarkdownParagraph(1, [spans, par.fields[1]]);
        case 6:
            return new MarkdownParagraph(6, [spans, par.fields[1]]);
        default:
            throw new Error("Incorrect ParagraphSpansInfo.\\nParameter name: ");
    }
}

export function MarkdownPatterns_ParagraphLeaf(_arg) {
    return _arg.fields[0];
}

export function MarkdownPatterns_ParagraphNested(_arg, pars) {
    const par = _arg.fields[0];
    switch (par.tag) {
        case 4:
            return new MarkdownParagraph(4, [par.fields[0], pars, par.fields[2]]);
        case 5:
            return new MarkdownParagraph(5, [concat(pars), par.fields[1]]);
        case 9: {
            const r_2 = par.fields[3];
            const alignments = par.fields[1];
            let rows;
            const n = length(alignments) | 0;
            const loop = (n_1_mut, left_mut, ansList_mut, curList_mut, items_mut) => {
                loop:
                while (true) {
                    const n_1 = n_1_mut, left = left_mut, ansList = ansList_mut, curList = curList_mut, items = items_mut;
                    if (isEmpty(items) && isEmpty(curList)) {
                        return reverse(ansList);
                    }
                    else if ((left === 0) ? true : isEmpty(items)) {
                        n_1_mut = n_1;
                        left_mut = n_1;
                        ansList_mut = cons(reverse(curList), ansList);
                        curList_mut = empty();
                        items_mut = items;
                        continue loop;
                    }
                    else {
                        n_1_mut = n_1;
                        left_mut = (left - 1);
                        ansList_mut = ansList;
                        curList_mut = cons(head(items), curList);
                        items_mut = tail(items);
                        continue loop;
                    }
                    break;
                }
            };
            rows = loop(n, n, empty(), empty(), pars);
            if (isEmpty(rows) ? true : (par.fields[0] == null)) {
                return new MarkdownParagraph(9, [undefined, alignments, rows, r_2]);
            }
            else {
                return new MarkdownParagraph(9, [head(rows), alignments, tail(rows), r_2]);
            }
        }
        default:
            throw new Error("Incorrect ParagraphNestedInfo.\\nParameter name: ");
    }
}

