import { Union, Record } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { ContactData as ContactData_1, ContactData_$reflection } from "../Omnicv.Shared/API.js";
import { union_type, class_type, unit_type, record_type, bool_type, option_type, string_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { value as value_3 } from "../fable_modules/fable-library-js.4.24.0/Option.js";
import { Cmd_OfPromise_either, Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { curry2 } from "../fable_modules/fable-library-js.4.24.0/Util.js";
import { anonService } from "../Omnicv.Client.Common/CommonServer.js";
import { Config_variable } from "../Omnicv.Client.Common/Extensions.js";
import { exnToDisplayString } from "../Omnicv.Client.Common/Utils.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { FlexFormBase$1__Render_2B595, FlexFormBase$1__SetState, FlexFormFields$1__AddTextArea_Z44011C05, FlexForm$1__get_Fields, FlexFormFields$1__AddText_Z44011C05, FlexForm$1_$ctor_2ABDDB55 } from "../Omnicv.Client.Common/Components/FlexForm.js";
import { createElement } from "react";
import { ofArray } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { reactApi } from "../fable_modules/Feliz.2.9.0/Interop.fs.js";

export class Model extends Record {
    constructor(ContactData, RecaptchaToken, IsRecaptchaReady, LoggedIn, Error$, ContactSent, IsLoading) {
        super();
        this.ContactData = ContactData;
        this.RecaptchaToken = RecaptchaToken;
        this.IsRecaptchaReady = IsRecaptchaReady;
        this.LoggedIn = LoggedIn;
        this.Error = Error$;
        this.ContactSent = ContactSent;
        this.IsLoading = IsLoading;
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Pages.ContactForm.Component.Model", [], Model, () => [["ContactData", ContactData_$reflection()], ["RecaptchaToken", option_type(string_type)], ["IsRecaptchaReady", bool_type], ["LoggedIn", bool_type], ["Error", option_type(string_type)], ["ContactSent", bool_type], ["IsLoading", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RecordUpdated", "RecaptchaReady", "Submitted", "RecaptchaSucceeded", "Succeeded", "Failed"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.ContactForm.Component.Msg", [], Msg, () => [[["Item", ContactData_$reflection()]], [], [], [["Item", string_type]], [["Item", unit_type]], [["Item", class_type("System.Exception")]]]);
}

export function init(session) {
    return [new Model(new ContactData_1("", (session.UserInfo != null) ? value_3(session.UserInfo).Email : "", "", undefined, ""), undefined, false, session.LoggedIn, undefined, false, false), Cmd_none()];
}

export function update(session, msg, model) {
    const cmdSubmit = (model_1) => Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, curry2(anonService.Contact)(model_1.ContactData), model_1.RecaptchaToken, () => (new Msg(4, [undefined])), (Item_1) => (new Msg(5, [Item_1])));
    switch (msg.tag) {
        case 2:
            return [new Model(model.ContactData, model.RecaptchaToken, model.IsRecaptchaReady, model.LoggedIn, model.Error, model.ContactSent, true), session.LoggedIn ? cmdSubmit(model) : Cmd_OfPromise_either(() => {
                const action = {
                    action: "submit",
                };
                const key = Config_variable("GOOGLE_RECAPTCHA_SITE_KEY");
                return grecaptcha.execute(key, action);
            }, undefined, (Item_2) => (new Msg(3, [Item_2])), (Item_3) => (new Msg(5, [Item_3])))];
        case 1:
            return [new Model(model.ContactData, model.RecaptchaToken, true, model.LoggedIn, model.Error, model.ContactSent, model.IsLoading), Cmd_none()];
        case 3: {
            const newModel = new Model(model.ContactData, msg.fields[0], model.IsRecaptchaReady, model.LoggedIn, model.Error, model.ContactSent, model.IsLoading);
            return [newModel, cmdSubmit(newModel)];
        }
        case 4:
            return [new Model(model.ContactData, model.RecaptchaToken, model.IsRecaptchaReady, model.LoggedIn, undefined, true, model.IsLoading), Cmd_none()];
        case 5:
            return [new Model(model.ContactData, model.RecaptchaToken, model.IsRecaptchaReady, model.LoggedIn, exnToDisplayString(msg.fields[0]), false, false), Cmd_none()];
        default:
            return [new Model(msg.fields[0], model.RecaptchaToken, model.IsRecaptchaReady, model.LoggedIn, model.Error, model.ContactSent, model.IsLoading), Cmd_none()];
    }
}

function _nonEmpty(query) {
    if (isNullOrWhiteSpace(query) ? true : (query.trim().length < 3)) {
        return "Search query too short";
    }
    else {
        return undefined;
    }
}

function viewForm(model, dispatch) {
    const form = FlexForm$1_$ctor_2ABDDB55((arg) => {
        dispatch(new Msg(0, [arg]));
    }, (_arg) => {
        dispatch(new Msg(2, []));
    }, undefined);
    FlexFormFields$1__AddText_Z44011C05(FlexForm$1__get_Fields(form), "Name", _nonEmpty, "User name");
    if (!model.LoggedIn) {
        FlexFormFields$1__AddText_Z44011C05(FlexForm$1__get_Fields(form), "Email", _nonEmpty, "Password");
    }
    FlexFormFields$1__AddTextArea_Z44011C05(FlexFormFields$1__AddText_Z44011C05(FlexForm$1__get_Fields(form), "Subject", _nonEmpty, "Remember me?"), "Message", _nonEmpty, "Remember me?");
    FlexFormBase$1__SetState(form, model.IsLoading ? true : !model.IsRecaptchaReady, model.Error);
    return FlexFormBase$1__Render_2B595(form, model.ContactData);
}

export function view(model, dispatch) {
    if (!model.IsRecaptchaReady) {
        grecaptcha.ready(() => {
            dispatch(new Msg(1, []));
        });
    }
    if (model.ContactSent) {
        return "Thank you for contacting us!";
    }
    else {
        const children = ofArray([createElement("p", {
            children: "Use this form to send us any questions, comments, bug reports or suggestions you might have",
        }), viewForm(model, dispatch)]);
        return createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        });
    }
}

