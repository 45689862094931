import { replace } from "../fable_modules/fable-library-js.4.24.0/RegExp.js";
import { countBy, List_distinct, List_distinctBy, Array_distinct, Array_distinctBy } from "../fable_modules/fable-library-js.4.24.0/Seq2.js";
import { take, item, map } from "../fable_modules/fable-library-js.4.24.0/Array.js";
import { join, isNullOrWhiteSpace, split } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { comparePrimitives, stringHash } from "../fable_modules/fable-library-js.4.24.0/Util.js";
import { sort, fold, mapIndexed, item as item_1, singleton, append, tryFindIndex, tryFind, exists as exists_1, isEmpty, ofArray, filter, map as map_1, empty } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { collect, ofList, tryFind as tryFind_1, map as map_2, filter as filter_1, tryHead, exists } from "../fable_modules/fable-library-js.4.24.0/Seq.js";
import { isLetterOrDigit } from "../fable_modules/fable-library-js.4.24.0/Char.js";
import { flatten } from "../fable_modules/fable-library-js.4.24.0/Option.js";
import { Record } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { record_type, list_type, string_type, int32_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { ofSeq, toList } from "../fable_modules/fable-library-js.4.24.0/Map.js";

const whiteSpaceReg = /\s\s+/gu;

export const tagsSeparator = ",";

export function collapseWhitespaces(str) {
    return replace(whiteSpaceReg, str, " ");
}

export function normaliseTag(tag) {
    return tag.trim().toLowerCase();
}

function splitClientTags(tags) {
    let array;
    if (tags == null) {
        return [];
    }
    else {
        return Array_distinctBy((s) => s.toLocaleLowerCase(), map((tag) => tag.trim(), (array = split(tags, [","], undefined, 0), array.filter((arg) => !isNullOrWhiteSpace(arg)))), {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        });
    }
}

export function splitTagToHierarchicalComponents(tag) {
    return split(tag, ["/"], undefined, 0);
}

export function concatHierarchicalComponents(components) {
    return join("/", components);
}

export function splitServerTags(tags) {
    if (tags == null) {
        return empty();
    }
    else {
        return map_1((tag) => tag.trim(), filter((arg) => !isNullOrWhiteSpace(arg), ofArray(split(tags, ["#"], undefined, 0))));
    }
}

export function validateTag(tag) {
    if (tag == null) {
        return "Must not be null";
    }
    else if (exists((c) => !(((isLetterOrDigit(c) ? true : (c === "_")) ? true : (c === "-")) ? true : (c === "/")), tag.split(""))) {
        return `Tag must contain only letters, numbers and characters '/', '-' and '_', was '${tag}'`;
    }
    else {
        return undefined;
    }
}

export function validateTags(tags) {
    return flatten(tryHead(filter_1((option) => (option != null), map_2(validateTag, tags))));
}

export function validateClientTags(tags) {
    return validateTags(splitClientTags(tags));
}

export function validateServerTags(tags) {
    return validateTags(splitServerTags(tags));
}

export function normaliseTags(tags) {
    if (tags == null) {
        return "";
    }
    else {
        return join(", ", Array_distinct(map(normaliseTag, split(tags, [tagsSeparator], undefined, 0)), {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        }));
    }
}

export function toClientView(tags) {
    return join(", ", splitServerTags(tags));
}

export const toClientListView = (arg) => List_distinctBy((s) => s.toLocaleLowerCase(), splitServerTags(arg), {
    Equals: (x, y) => (x === y),
    GetHashCode: stringHash,
});

export class TagTreeNode extends Record {
    constructor(Id, Tag, Caption, Children) {
        super();
        this.Id = (Id | 0);
        this.Tag = Tag;
        this.Caption = Caption;
        this.Children = Children;
    }
}

export function TagTreeNode_$reflection() {
    return record_type("Omnicv.Shared.TagUtils.TagTreeNode", [], TagTreeNode, () => [["Id", int32_type], ["Tag", string_type], ["Caption", string_type], ["Children", list_type(TagTreeNode_$reflection())]]);
}

export function TagTreeNode__get_IsLeaf(this$) {
    return isEmpty(this$.Children);
}

export function TagTreeNode__Match_Z721C83C5(this$, query) {
    if ((this$.Tag.indexOf(query) >= 0) ? true : (this$.Caption.indexOf(query) >= 0)) {
        return true;
    }
    else {
        return exists_1((child) => TagTreeNode__Match_Z721C83C5(child, query), this$.Children);
    }
}

export function TagTreeNode_Default_Z721C83C5(caption) {
    return new TagTreeNode(0, caption, caption, empty());
}

export function tryFindNode(pred, trees) {
    if (isEmpty(trees)) {
        return undefined;
    }
    else {
        const res = tryFind(pred, trees);
        if (res == null) {
            return flatten(tryFind_1((option) => (option != null), map_2((child) => tryFindNode(pred, child.Children), ofList(trees))));
        }
        else {
            return res;
        }
    }
}

export function toClientTreeView(tagsMap) {
    let id = 1;
    const addToTree = (tree, newTag, pos) => {
        if (pos >= newTag.length) {
            return tree;
        }
        else {
            const section = item(pos, newTag);
            const matchValue = tryFindIndex((child) => (child.Caption === section), tree.Children);
            if (matchValue == null) {
                const tag = concatHierarchicalComponents(take(pos + 1, newTag));
                id = ((id + 1) | 0);
                return new TagTreeNode(tree.Id, tree.Tag, tree.Caption, append(tree.Children, singleton(addToTree(new TagTreeNode(id, tag, section, empty()), newTag, pos + 1))));
            }
            else {
                const index = matchValue | 0;
                const newNode = addToTree(item_1(index, tree.Children), newTag, pos + 1);
                return new TagTreeNode(tree.Id, tree.Tag, tree.Caption, mapIndexed((i, child_1) => {
                    if (i === index) {
                        return newNode;
                    }
                    else {
                        return child_1;
                    }
                }, tree.Children));
            }
        }
    };
    return fold((tree_1, tag_2) => addToTree(tree_1, tag_2, 0), TagTreeNode_Default_Z721C83C5(""), map_1(splitTagToHierarchicalComponents, List_distinct(sort(map_1((tupledArg) => tupledArg[0], toList(tagsMap)), {
        Compare: comparePrimitives,
    }), {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: stringHash,
    }))).Children;
}

export function concatToServer(tags) {
    return ("#" + join("#", filter_1((arg) => !isNullOrWhiteSpace(arg), tags))) + "#";
}

export function addTag(tags, tag) {
    const currentTags = splitServerTags(tags);
    if (exists_1((t) => (t.toLocaleLowerCase() === tag.toLocaleLowerCase()), currentTags)) {
        return tags;
    }
    else {
        return concatToServer(append(currentTags, singleton(tag)));
    }
}

export function removeTag(tags, tag) {
    return concatToServer(filter((t) => (t.toLocaleLowerCase() !== tag.toLocaleLowerCase()), splitServerTags(tags)));
}

export function fromClientView(tags) {
    return concatToServer(splitClientTags(tags));
}

export function tagsToMap(tags) {
    return ofSeq(countBy((x) => x, collect(toClientListView, tags), {
        Equals: (x_1, y) => (x_1 === y),
        GetHashCode: stringHash,
    }), {
        Compare: comparePrimitives,
    });
}

