import { filter, map, fold } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { FSharpResult$2, Result_Bind } from "../fable_modules/fable-library-js.4.24.0/Result.js";
import { FSharpMap__get_Count, fold as fold_1, ofList } from "../fable_modules/fable-library-js.4.24.0/Map.js";
import { value as value_2 } from "../fable_modules/fable-library-js.4.24.0/Option.js";
import { compare, comparePrimitives } from "../fable_modules/fable-library-js.4.24.0/Util.js";
import { match } from "../fable_modules/fable-library-js.4.24.0/RegExp.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { exists } from "../fable_modules/fable-library-js.4.24.0/Seq.js";

export function Constraints_applyConstraints(constraints, name, value) {
    return fold((state, constr) => Result_Bind(constr(name), state), new FSharpResult$2(0, [value]), constraints);
}

export function Constraints_applyConstraintsGetErrors(constraints, name, value) {
    const result = Constraints_applyConstraints(constraints, name, value);
    if (result.tag === 1) {
        return result.fields[0];
    }
    else {
        return undefined;
    }
}

export function Constraints_collectConstraintsErrors(errors) {
    return ofList(map(value_2, filter((option) => (option != null), errors)), {
        Compare: comparePrimitives,
    });
}

export function Constraints_regexConstraint(regex, name, value) {
    if (value == null) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be not null`]]);
    }
    else if (!(match(regex, value) != null)) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' does not match the pattern: ${regex}`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_minLengthConstraint(minLength, name, value) {
    if (value == null) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be not null`]]);
    }
    else if (value.length < minLength) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be at least ${minLength} characters long; ${value.length} given`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_maxLengthConstraint(maxLength, name, value) {
    if (value == null) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be not null`]]);
    }
    else if (value.length > maxLength) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be no more than ${maxLength} characters long; ${value.length} given`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_minMaxLengthConstraint(minLength, maxLength, name, value) {
    return Result_Bind((value_1) => Constraints_maxLengthConstraint(maxLength, name, value_1), Constraints_minLengthConstraint(minLength, name, value));
}

export function Constraints_notEmptyOrWhitespaceConstraint(name, value) {
    if (isNullOrWhiteSpace(value)) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must not be empty`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_minOptLengthConstraint(minLength, name, value) {
    if ((value != null) && (value_2(value).length < minLength)) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be at least ${minLength} long; ${value_2(value).length} given`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_maxOptLengthConstraint(maxLength, name, value) {
    if ((value != null) && (value_2(value).length > maxLength)) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be no more than ${maxLength} long; ${value_2(value).length} given`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_minMaxOptLengthConstraint(minLength, maxLength, name, value) {
    return Result_Bind((value_1) => Constraints_maxOptLengthConstraint(maxLength, name, value_1), Constraints_minOptLengthConstraint(minLength, name, value));
}

export function Constraints_minConstraint(minValue, name, value) {
    if (compare(value, minValue) < 0) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be at least ${minValue}, ${value} given`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_minOptConstraint(minValue, name, value) {
    if ((value != null) && (compare(value_2(value), minValue) < 0)) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be at least ${minValue}, ${value} given`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_greaterThanConstraint(minValue, name, value) {
    if (compare(value, minValue) <= 0) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be greater than ${minValue}, ${value} given`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_isValidCurrency(name, value) {
    if (isNullOrWhiteSpace(value) ? true : (value.length !== 3)) {
        return new FSharpResult$2(1, [[name, `Bad currency code ${value}`]]);
    }
    else if (exists((c) => {
        if (c < "A") {
            return true;
        }
        else {
            return c > "Z";
        }
    }, value.split(""))) {
        return new FSharpResult$2(1, [[name, `Bad currency code ${value}`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_isValidCurrencyOpt(name, value) {
    if (value == null) {
        return new FSharpResult$2(0, [value]);
    }
    else {
        const matchValue = Constraints_isValidCurrency(name, value_2(value));
        if (matchValue.tag === 1) {
            return new FSharpResult$2(1, [matchValue.fields[0]]);
        }
        else {
            return new FSharpResult$2(0, [matchValue.fields[0]]);
        }
    }
}

export function Constraints_maxConstraint(maxValue, name, value) {
    if (compare(value, maxValue) > 0) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be no more than ${maxValue}, ${value} given`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_maxOptConstraint(maxValue, name, value) {
    if ((value != null) && (compare(value_2(value), maxValue) > 0)) {
        return new FSharpResult$2(1, [[name, `The value of '${name}' must be no more than ${maxValue}, ${value} given`]]);
    }
    else {
        return new FSharpResult$2(0, [value]);
    }
}

export function Constraints_minMaxConstraint(minValue, maxValue, name, value) {
    return Result_Bind((value_1) => Constraints_maxConstraint(maxValue, name, value_1), Constraints_minConstraint(minValue, name, value));
}

export function Constraints_minMaxOptConstraint(minValue, maxValue, name, value) {
    return Result_Bind((value_1) => Constraints_maxOptConstraint(maxValue, name, value_1), Constraints_minOptConstraint(minValue, name, value));
}

export function Constraints_noneOrSomeTogether(name, mapping) {
    const numTrues = fold_1((count, _arg, value) => (value ? (count + 1) : count), 0, mapping) | 0;
    if ((numTrues === 0) ? true : (numTrues === FSharpMap__get_Count(mapping))) {
        return new FSharpResult$2(0, [mapping]);
    }
    else {
        return new FSharpResult$2(1, [[name, "All fields must be None or have a value"]]);
    }
}

export function Modifiers_ensureValidTrimmed(value) {
    if (value == null) {
        return "";
    }
    else {
        return value.trim();
    }
}

