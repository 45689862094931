import { Union, Record } from "../fable-library-js.4.24.0/Types.js";
import { class_type, union_type, list_type, record_type, bool_type, string_type } from "../fable-library-js.4.24.0/Reflection.js";
import { isNullOrWhiteSpace } from "../fable-library-js.4.24.0/String.js";
import { map } from "../fable-library-js.4.24.0/Array.js";
import { map as map_1, delay, toArray } from "../fable-library-js.4.24.0/Seq.js";
import "./SelectSearch.css";

export class SelectItem extends Record {
    constructor(value, name, disabled) {
        super();
        this.value = value;
        this.name = name;
        this.disabled = disabled;
    }
}

export function SelectItem_$reflection() {
    return record_type("Feliz.SelectSearch.SelectItem", [], SelectItem, () => [["value", string_type], ["name", string_type], ["disabled", bool_type]]);
}

export class SelectGroup extends Record {
    constructor(name, items) {
        super();
        this.name = name;
        this.items = items;
    }
}

export function SelectGroup_$reflection() {
    return record_type("Feliz.SelectSearch.SelectGroup", [], SelectGroup, () => [["name", string_type], ["items", list_type(SelectItem_$reflection())]]);
}

export class SelectOption extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Item", "Group"];
    }
}

export function SelectOption_$reflection() {
    return union_type("Feliz.SelectSearch.SelectOption", [], SelectOption, () => [[["Item", SelectItem_$reflection()]], [["Item", SelectGroup_$reflection()]]]);
}

export function Interop_createDefaultFilter(predicate) {
    return (selectItems) => ((searchQuery) => (isNullOrWhiteSpace(searchQuery) ? selectItems : map((jsItem_1) => {
        if (jsItem_1.type === "group") {
            let filteredItems;
            const array_1 = jsItem_1.items;
            filteredItems = array_1.filter((groupItem) => {
                let name_1;
                return predicate((name_1 = groupItem.name, new SelectItem(groupItem.value, name_1, groupItem.disabled)), searchQuery);
            });
            return Object.assign({}, jsItem_1, {
                items: filteredItems,
            });
        }
        else {
            return jsItem_1;
        }
    }, selectItems.filter((jsItem) => {
        let name;
        if (jsItem.type === "group") {
            return true;
        }
        else {
            return predicate((name = jsItem.name, new SelectItem(jsItem.value, name, jsItem.disabled)), searchQuery);
        }
    }))));
}

export const Interop_defaultProps = {
    filterOptions: Interop_createDefaultFilter((item, query) => (item.name.toLocaleLowerCase().indexOf(query.trim().toLocaleLowerCase()) >= 0)),
};

export class OptionRendererProps extends Record {
    constructor(attributes, className, option, selected, highlighted) {
        super();
        this.attributes = attributes;
        this.className = className;
        this.option = option;
        this.selected = selected;
        this.highlighted = highlighted;
    }
}

export function OptionRendererProps_$reflection() {
    return record_type("Feliz.SelectSearch.OptionRendererProps", [], OptionRendererProps, () => [["attributes", class_type("System.Collections.Generic.IEnumerable`1", [class_type("Feliz.IReactProperty")])], ["className", string_type], ["option", SelectItem_$reflection()], ["selected", bool_type], ["highlighted", bool_type]]);
}

export class ValueRendererProps extends Record {
    constructor(attributes, focus, disabled, fetching, search, displayValue, values) {
        super();
        this.attributes = attributes;
        this.focus = focus;
        this.disabled = disabled;
        this.fetching = fetching;
        this.search = search;
        this.displayValue = displayValue;
        this.values = values;
    }
}

export function ValueRendererProps_$reflection() {
    return record_type("Feliz.SelectSearch.ValueRendererProps", [], ValueRendererProps, () => [["attributes", class_type("System.Collections.Generic.IEnumerable`1", [class_type("Feliz.IReactProperty")])], ["focus", bool_type], ["disabled", bool_type], ["fetching", bool_type], ["search", string_type], ["displayValue", string_type], ["values", list_type(string_type)]]);
}

export class selectSearch {
    constructor() {
    }
}

export function selectSearch_$reflection() {
    return class_type("Feliz.SelectSearch.selectSearch", undefined, selectSearch);
}

/**
 * Sets the options of the select control
 */
export function selectSearch_options_3A40D30D(items) {
    return ["options", toArray(delay(() => map_1((item) => ({
        value: item.value,
        name: item.name,
        disabled: item.disabled,
    }), items)))];
}

/**
 * Sets the options of the select control
 */
export function selectSearch_options_626B3067(groups) {
    return ["options", toArray(delay(() => map_1((group) => ({
        type: "group",
        name: group.name,
        items: toArray(delay(() => map_1((item) => ({
            value: item.value,
            name: item.name,
        }), group.items))),
    }), groups)))];
}

/**
 * Sets the options of the select control
 */
export function selectSearch_options_113CE11B(mixedOptions) {
    return ["options", toArray(delay(() => map_1((option) => {
        if (option.tag === 1) {
            const group = option.fields[0];
            return {
                type: "group",
                name: group.name,
                items: toArray(delay(() => map_1((item_1) => ({
                    value: item_1.value,
                    name: item_1.name,
                    disabled: item_1.disabled,
                }), group.items))),
            };
        }
        else {
            const item = option.fields[0];
            return {
                value: item.value,
                name: item.name,
                disabled: item.disabled,
            };
        }
    }, mixedOptions)))];
}

/**
 * When search is enabled, this function allows to define how items are filtered.
 * By default, the built-in filtering checks whether the name of the select item contains input query (case-insensitive)
 */
export function selectSearch_filterOptions_Z7A2530AD(predicate) {
    return ["filterOptions", Interop_createDefaultFilter(predicate)];
}


export class selectSearchModule_printOptions {
    constructor() {
    }
}

export function selectSearchModule_printOptions_$reflection() {
    return class_type("Feliz.SelectSearch.selectSearchModule.printOptions", undefined, selectSearchModule_printOptions);
}

export class selectSearchModule_autoComplete {
    constructor() {
    }
}

export function selectSearchModule_autoComplete_$reflection() {
    return class_type("Feliz.SelectSearch.selectSearchModule.autoComplete", undefined, selectSearchModule_autoComplete);
}

