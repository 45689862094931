import { Loader } from '@googlemaps/js-api-loader';
import { DateTime } from "luxon";
import React from 'react';
import {pl} from "date-fns/locale";

// function dynamicallyLoadScript(url) {
//     var script = document.createElement("script");  // create a script DOM node
//     script.src = url;  // set its src to the provided URL
//     script.async = true;
//     script.type = 'text/javascript';
//     document.body.appendChild(script);  // add it to the end of the head section of the page (could change 'head' to 'body' to add it to the end of the body section instead)
// }

// THIS IS A SPECIAL MAGIC VALUE, DO NOT TOUCH. IT WILL BE UPDATED AUTOMATICALLY BY RELEASE SCRIPT
const _releaseVersion = 'omnicv-20241126-317';
// END OF MAGIC

var _configuration = undefined;

export function getConfigVariable(name) {
    if (name === "RELEASE_VERSION")
        return _releaseVersion;
    if (_configuration == undefined) {
        return "";
    }
    if (_configuration.has(name)) {
        return _configuration.get(name);
    }
    return "";
}

export function updateConfiguration(configuration) {
    console.info("Received configuration", configuration);
    _configuration = configuration;
}

export function getReactVersion() {
    return React.version;
}

export function getDayName(date, locale)
{
    if (locale === "")
        locale = undefined;
    return date.toLocaleDateString(locale, { weekday: 'long' });
}

export function getNow() {
    return new Date();
}


export function loadGoogleMapsApi(key) {
    const loader = new Loader({
        apiKey: key,
        version: "weekly",
        libraries: ["places"]
    });

    // TODO fix deprecated API
    // https://github.com/googlemaps/js-api-loader/issues/837
    return loader.load();//importLibrary('places');
}

export function registerPlacesAutoComplete(google, inputId, callback) {
    console.log("Registering element for Google map search: " + inputId)
    var elem = document.getElementById(inputId);
    var places = new google.maps.places.Autocomplete(elem);
    // remove all previous events
    google.maps.event.clearInstanceListeners(places);

    var placesService = new google.maps.places.PlacesService(elem);

    // register new event
    places.addListener('place_changed', () => {
        var place = places.getPlace();
        if (!place.geometry || !place.place_id) {
            return;
        }
        callback(place);

        if (!place.utc_offset_minutes) {
            var request = new google.maps.places.PlaceDetailsRequest(place.place_id);
            placesService.getDetails( request, (result, status) => {
                if (result)
                    callback(result);
            });
        }
        // var address = place.formatted_address;
        // var latitude = place.geometry.location.A;
        // var longitude = place.geometry.location.F;
        // var mesg = "Address: " + address;
        // mesg += "\nLatitude: " + latitude;
        // mesg += "\nLongitude: " + longitude;
        // alert(mesg);
    });



    elem.disabled = false;
    return places;
}

export function getPosition(options) {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
}

export function createCircle() {
    return new google.maps.Circle({});
}

const currencyDisplayNames = new Intl.DisplayNames(undefined, { type: 'currency' });

export function formatCurrencyName(currency) {
    return currencyDisplayNames.of(currency);
}

export function formatCurrencyValue(currency, value) {
    var formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency,

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(value);
}

const numberFormatter = new Intl.NumberFormat(undefined, {
    maximumSignificantDigits: 2
});

export function formatNumericValue(value) {
    return numberFormatter.format(value);
}

export function getTimezoneName() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getTimezoneOffset() {
    var res = new Date().getTimezoneOffset();
    return -res;
}

function _dateToTz(date, tz) {
    return DateTime.fromJSDate(date).setZone(tz);
}

export function dateToLuxonTz(date, tz) {
    return _dateToTz(date, tz);
}

export function dateToTz(date, tz) {
    return _dateToTz(date, tz).toJSDate();
}

export function dateToLuxonUtcOffset(date, utcOffsetMins) {
    var hours = Math.abs(utcOffsetMins) / 60;
    var minutes = Math.abs(utcOffsetMins) - (hours * 60);
    var zone = "UTC";
    if (utcOffsetMins > 0)
        zone += "+";
    else
        zone += "-";
    zone += hours.toString();
    if (minutes > 0)
        zone += ":" + minutes.toString();
    return dateToLuxonTz(date, zone);
}

export function createAudio(path) {
    return new Audio(path);
}

// export function latLongToLongInt(lat, long)
// {
//     var buff = new ArrayBuffer(8);
//     var result = new BigInt64Array(buff);
//     var values = new Float32Array(buff);
//     values[0] = lat;
//     values[1] = long;
//     return BigInt(result[0]);
// }


// export function longIntToLatLong(value)
// {
//     var buff = new ArrayBuffer(8);
//     var input = new BigInt64Array(buff);
//     input[0] = value;
//     var values = new Float32Array(buff);
//     var lat = values[0];
//     var long = values[1];
//     return [lat, long];
// }
