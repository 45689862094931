import { Union, Record } from "../../fable_modules/fable-library-js.4.24.0/Types.js";
import { DataPoint_$reflection } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import { union_type, class_type, record_type, option_type, string_type, bool_type, list_type } from "../../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { ofArray, length, isEmpty, singleton, empty } from "../../fable_modules/fable-library-js.4.24.0/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { responsiveChart, exnToDisplayString } from "../../Omnicv.Client.Common/Utils.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createObj, equals, curry2 } from "../../fable_modules/fable-library-js.4.24.0/Util.js";
import { valuesToTable, financialService } from "./FinancialCommon.js";
import { Session__GetCurrentProfileId } from "../../Omnicv.Client.Common/SharedView.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.24.0/Seq.js";
import { Class, closeable } from "../../Omnicv.Client.Common/Components/Notification.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.24.0/Util.js";
import { Option, h4, h1 } from "../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.9.0/Interop.fs.js";
import { ResponsiveContainer } from "recharts";
import { createElement } from "react";
import { reactApi as reactApi_1 } from "../../fable_modules/Feliz.2.9.0/Interop.fs.js";

export const targetCurrency = "USD";

export class Model extends Record {
    constructor(Data, InProgress, Error$) {
        super();
        this.Data = Data;
        this.InProgress = InProgress;
        this.Error = Error$;
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Pages.Financial.ViewAssetsSummary.Component.Model", [], Model, () => [["Data", list_type(DataPoint_$reflection())], ["InProgress", bool_type], ["Error", option_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RequestData", "SuccessData", "Failure"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Financial.ViewAssetsSummary.Component.Msg", [], Msg, () => [[], [["Item", list_type(DataPoint_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export function init() {
    return [new Model(empty(), false, undefined), singleton((dispatch) => {
        dispatch(new Msg(0, []));
    })];
}

export function update(session, msg, model) {
    switch (msg.tag) {
        case 1:
            return [new Model(msg.fields[0], false, undefined), Cmd_none()];
        case 2:
            return [new Model(empty(), false, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default:
            return [new Model(model.Data, true, model.Error), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, curry2(financialService.GetAssetValuesForChart)(Session__GetCurrentProfileId(session)), targetCurrency, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])))];
    }
}

export function view(model, dispatch) {
    const children_2 = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Financial error: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => {
            let children;
            return isEmpty(model.Data) ? singleton_1("No data") : singleton_1((children = toList(delay(() => append(singleton_1(h1(empty())(singleton("All asset values"))), delay(() => append(singleton_1(h4(singleton(new Option(6, [])))(singleton(`Currency: ${targetCurrency}`))), delay(() => {
                let properties, value_1;
                return append((length(model.Data) > 1) ? singleton_1((properties = ofArray([(value_1 = (100 + "%"), (equals(value_1, 100 + "%") ? true : equals(value_1, 100 + "%")) ? ["width", 99 + "%"] : ["width", value_1]), ["height", 300], ["children", responsiveChart("Value change over time", model.Data)]]), reactApi.createElement(ResponsiveContainer, createObj(properties)))) : empty_1(), delay(() => append(singleton_1(h4(empty())(singleton("Values"))), delay(() => singleton_1(valuesToTable(targetCurrency, model.Data, undefined))))));
            })))))), createElement("div", {
                children: reactApi_1.Children.toArray(Array.from(children)),
            })));
        }));
    }));
    return createElement("div", {
        children: reactApi_1.Children.toArray(Array.from(children_2)),
    });
}

