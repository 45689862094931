import { Record } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { bool_type, record_type, lambda_type, unit_type, tuple_type, class_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { Component } from "react";
import * as react from "react";

export class ErrorBoundaryProps extends Record {
    constructor(Inner, ErrorComponent, OnError) {
        super();
        this.Inner = Inner;
        this.ErrorComponent = ErrorComponent;
        this.OnError = OnError;
    }
}

export function ErrorBoundaryProps_$reflection() {
    return record_type("ReactErrorBoundary.ErrorBoundaryProps", [], ErrorBoundaryProps, () => [["Inner", class_type("Fable.React.ReactElement")], ["ErrorComponent", class_type("Fable.React.ReactElement")], ["OnError", lambda_type(tuple_type(class_type("System.Exception"), class_type("ReactErrorBoundary.InfoComponentObject")), unit_type)]]);
}

export class ErrorBoundaryState extends Record {
    constructor(HasErrors) {
        super();
        this.HasErrors = HasErrors;
    }
}

export function ErrorBoundaryState_$reflection() {
    return record_type("ReactErrorBoundary.ErrorBoundaryState", [], ErrorBoundaryState, () => [["HasErrors", bool_type]]);
}

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = (new ErrorBoundaryState(false));
    }
    componentDidCatch(error, info) {
        const this$ = this;
        const info_1 = info;
        (this$.props).OnError([error, info_1]);
        this$.setState((_arg, _arg_1) => (new ErrorBoundaryState(true)));
    }
    render() {
        const this$ = this;
        return (this$.state).HasErrors ? (this$.props).ErrorComponent : (this$.props).Inner;
    }
}

export function ErrorBoundary_$reflection() {
    return class_type("ReactErrorBoundary.ErrorBoundary", undefined, ErrorBoundary, class_type("Fable.React.Component`2", [ErrorBoundaryProps_$reflection(), ErrorBoundaryState_$reflection()], Component));
}

export function ErrorBoundary_$ctor_5B6311C4(props) {
    return new ErrorBoundary(props);
}

export function renderCatchSimple(errorElement, element) {
    const props = new ErrorBoundaryProps(element, errorElement, (_arg) => {
    });
    return react.createElement(ErrorBoundary, props);
}

export function renderCatchFn(onError, errorElement, element) {
    const props = new ErrorBoundaryProps(element, errorElement, onError);
    return react.createElement(ErrorBoundary, props);
}

