import { Remoting_buildProxy_64DC51C } from "../../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "../../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { printf, toText } from "../../fable_modules/fable-library-js.4.24.0/String.js";
import { InteractionRecord_$reflection, ReviewRecord_$reflection, DiaryRecord_$reflection } from "../Omnicv.Diary.Shared/Data.js";
import { ReminderService_$reflection, ReminderService_RouteBuilder, UnifiedService_$reflection, UnifiedService_RouteBuilder, RecordService$1_$reflection } from "../Omnicv.Diary.Shared/DiaryAPI.js";

export const diaryService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, m) => toText(printf("/api/auth/modules/dm/%s/%s"))("DiaryRecord")(m), RemotingModule_createApi()), RecordService$1_$reflection(DiaryRecord_$reflection()));

export const reviewService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, m) => toText(printf("/api/auth/modules/dm/%s/%s"))("ReviewRecord")(m), RemotingModule_createApi()), RecordService$1_$reflection(ReviewRecord_$reflection()));

export const interactionService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, m) => toText(printf("/api/auth/modules/dm/%s/%s"))("InteractionRecord")(m), RemotingModule_createApi()), RecordService$1_$reflection(InteractionRecord_$reflection()));

export const unifiedService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(UnifiedService_RouteBuilder, RemotingModule_createApi()), UnifiedService_$reflection());

export const reminderService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(ReminderService_RouteBuilder, RemotingModule_createApi()), ReminderService_$reflection());

