import { Union } from "../../../fable-library-js.4.24.0/Types.js";
import { Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions__AddProp_ZCC5A61F, Common_GenericOptions__AddCaseName_1505, Color_ofColor, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Color_IColor_$reflection, Size_ISize_$reflection } from "../../Common.fs.js";
import { union_type, lambda_type, unit_type, list_type, class_type, string_type, bool_type } from "../../../fable-library-js.4.24.0/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library-js.4.24.0/MapUtil.js";
import { Reflection_getCaseName } from "../../Common.fs.js";
import { DOMAttr, Prop, HTMLAttr } from "../../../Fable.React.9.4.0/Fable.React.Props.fs.js";
import { equals } from "../../../fable-library-js.4.24.0/Util.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Size", "is-fullwidth", "is-inline", "is-loading", "is-focused", "is-active", "IsReadOnly", "Color", "Id", "Disabled", "Value", "DefaultValue", "ValueOrDefault", "Placeholder", "Props", "OnChange", "Ref", "CustomClass", "has-fixed-size", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Textarea.Option", [], Option, () => [[["Item", Size_ISize_$reflection()]], [], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", Color_IColor_$reflection()]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", lambda_type(class_type("Browser.Types.Event", undefined), unit_type)]], [["Item", lambda_type(class_type("Browser.Types.Element", undefined), unit_type)]], [["Item", string_type]], [], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <textarea class="textarea"></textarea>
 */
export function textarea(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        let matchResult, state;
        switch (option.tag) {
            case 7: {
                matchResult = 1;
                break;
            }
            case 1:
            case 18:
            case 2: {
                matchResult = 2;
                break;
            }
            case 8: {
                matchResult = 4;
                break;
            }
            case 9: {
                matchResult = 5;
                break;
            }
            case 6: {
                matchResult = 6;
                break;
            }
            case 10: {
                matchResult = 7;
                break;
            }
            case 11: {
                matchResult = 8;
                break;
            }
            case 12: {
                matchResult = 9;
                break;
            }
            case 13: {
                matchResult = 10;
                break;
            }
            case 15: {
                matchResult = 11;
                break;
            }
            case 16: {
                matchResult = 12;
                break;
            }
            case 14: {
                matchResult = 13;
                break;
            }
            case 17: {
                matchResult = 14;
                break;
            }
            case 19: {
                matchResult = 15;
                break;
            }
            case 3: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 4: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 5: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            default:
                matchResult = 0;
        }
        switch (matchResult) {
            case 0:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            case 1:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            case 2:
                return Common_GenericOptions__AddCaseName_1505(result, option);
            case 3:
                if (state) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            case 4:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(99, [option.fields[0]]));
            case 5:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(79, [option.fields[0]]));
            case 6:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(132, [option.fields[0]]));
            case 7:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(161, [option.fields[0]]));
            case 8:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(1, [option.fields[0]]));
            case 9: {
                const valueOrDefault = option.fields[0];
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new Prop(1, [(e) => {
                    if (!(e == null) && !equals(e.value, valueOrDefault)) {
                        e.value = valueOrDefault;
                    }
                }]));
            }
            case 10:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(128, [option.fields[0]]));
            case 11:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new DOMAttr(9, [option.fields[0]]));
            case 12:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new Prop(1, [option.fields[0]]));
            case 13:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 14:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
        }
    }, "textarea"), (props_1, children_1) => react.createElement("textarea", keyValueList(props_1, 1), ...children_1), children);
}

