import { Union, Record } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { union_type, class_type, record_type, bool_type, list_type, option_type, string_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { SummaryEntry_$reflection } from "../Omnicv.Shared/API.js";
import { map, isEmpty, ofArray, cons, singleton, empty } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { commonAuthService, anonService } from "../Omnicv.Client.Common/CommonServer.js";
import { int32ToString, curry2 } from "../fable_modules/fable-library-js.4.24.0/Util.js";
import { Session__GetCurrentProfileId } from "../Omnicv.Client.Common/SharedView.js";
import { exnToDisplayString } from "../Omnicv.Client.Common/Utils.js";
import { Option, columns } from "../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { column } from "../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.24.0/Seq.js";
import { h3, h1 } from "../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { Config_releaseVersion } from "../Omnicv.Client.Common/Extensions.js";
import * as common from "../../src/javascript/common.js";
import { Class, closeable } from "../Omnicv.Client.Common/Components/Notification.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.24.0/Util.js";
import { content as content_1, card } from "../fable_modules/Fulma.3.0.0/Components/Card.fs.js";
import { content as content_2 } from "../fable_modules/Fulma.3.0.0/Elements/Content.fs.js";
import { createElement } from "react";
import { Markdown_ToHtml_1BFEAEDB } from "../fable_modules/Fable.Formatting.Markdown.1.0.1/Markdown.fs.js";
import { reactApi } from "../fable_modules/Feliz.2.9.0/Interop.fs.js";

export class Model extends Record {
    constructor(Information, Summaries, Error$, InProgress) {
        super();
        this.Information = Information;
        this.Summaries = Summaries;
        this.Error = Error$;
        this.InProgress = InProgress;
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Pages.About.Component.Model", [], Model, () => [["Information", option_type(string_type)], ["Summaries", list_type(SummaryEntry_$reflection())], ["Error", option_type(string_type)], ["InProgress", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Succeeded", "SucceededSummaries", "Failed"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.About.Component.Msg", [], Msg, () => [[["Item", string_type]], [["Item", list_type(SummaryEntry_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export function init(session) {
    return [new Model(undefined, empty(), undefined, false), Cmd_batch(cons(Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, anonService.About, undefined, (Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(2, [Item_1]))), session.LoggedIn ? singleton(Cmd_OfAsyncWith_either((x_1) => {
        Cmd_OfAsync_start(x_1);
    }, curry2(commonAuthService.GetContentSummary)(Session__GetCurrentProfileId(session)), 1, (Item_2) => (new Msg(1, [Item_2])), (Item_3) => (new Msg(2, [Item_3])))) : empty()))];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1:
            return [new Model(model.Information, msg.fields[0], model.Error, false), Cmd_none()];
        case 2:
            return [new Model(undefined, model.Summaries, exnToDisplayString(msg.fields[0]), false), Cmd_none()];
        default:
            return [new Model(msg.fields[0], model.Summaries, model.Error, false), Cmd_none()];
    }
}

function displaySummary(summary) {
    return columns(singleton(new Option(4, [])), ofArray([column(empty(), singleton(summary.Caption)), column(empty(), singleton(summary.ContentType)), column(empty(), singleton(int32ToString(summary.Count)))]));
}

export function view(model, dispatch) {
    const children_4 = toList(delay(() => append(singleton_1(h1(empty())(singleton("About"))), delay(() => append(singleton_1(columns(empty(), ofArray([column(empty(), singleton("Client version")), column(empty(), singleton(Config_releaseVersion()))]))), delay(() => append(singleton_1(columns(empty(), ofArray([column(empty(), singleton("React version")), column(empty(), singleton(common.getReactVersion()))]))), delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't fetch information: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => {
            let children_2;
            const matchValue_1 = model.Information;
            if (matchValue_1 != null) {
                const value = matchValue_1;
                return singleton_1((children_2 = toList(delay(() => append(singleton_1(card(empty(), singleton(content_1(empty(), singleton(content_2(empty(), singleton(createElement("div", {
                    dangerouslySetInnerHTML: {
                        __html: Markdown_ToHtml_1BFEAEDB(value),
                    },
                })))))))), delay(() => (!isEmpty(model.Summaries) ? append(singleton_1(h3(empty())(singleton("Summaries"))), delay(() => {
                    let children;
                    return singleton_1((children = map(displaySummary, model.Summaries), createElement("div", {
                        children: reactApi.Children.toArray(Array.from(children)),
                    })));
                })) : empty_1()))))), createElement("div", {
                    children: reactApi.Children.toArray(Array.from(children_2)),
                })));
            }
            else {
                return singleton_1(createElement("i", {
                    children: ["Not loaded"],
                }));
            }
        }));
    }))))))));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    });
}

