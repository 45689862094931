import { Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_Z4E55E0BD, Modifier_IModifier_$reflection, Common_parseOptions, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7 } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library-js.4.24.0/MapUtil.js";
import { Union } from "../../fable-library-js.4.24.0/Types.js";
import { union_type, string_type, list_type, class_type } from "../../fable-library-js.4.24.0/Reflection.js";

/**
 * Generate <div class="card"></div>
 */
export function card(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "card"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="card-header"></div>
 */
export function header(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "card-header"), (props, children_1) => react.createElement("header", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="card-content"></div>
 */
export function content(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "card-content"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="card-footer"></div>
 */
export function footer(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "card-footer"), (props, children_1) => react.createElement("footer", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="card-image"></div>
 */
export function image(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "card-image"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export class Header_Title_Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-centered", "Props", "CustomClass", "Modifiers"];
    }
}

export function Header_Title_Option_$reflection() {
    return union_type("Fulma.Card.Header.Title.Option", [], Header_Title_Option, () => [[], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <a class="card-header-icon"></a>
 */
export function Header_icon(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "card-header-icon"), (props, children_1) => react.createElement("a", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <p class="card-header-title"></p>
 */
export function Header_title(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 1:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 3:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddCaseName_1505(result, option);
        }
    }, "card-header-title"), (props_1, children_1) => react.createElement("p", keyValueList(props_1, 1), ...children_1), children);
}

export function Footer_itemView(element, options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "card-footer-item"), element, children);
}

/**
 * Generate <div class="card-footer-item"></div>
 */
export function Footer_div(x, y) {
    return Footer_itemView((props, children) => react.createElement("div", keyValueList(props, 1), ...children), x, y);
}

/**
 * Generate <p class="card-footer-item"></p>
 */
export function Footer_p(x, y) {
    return Footer_itemView((props, children) => react.createElement("p", keyValueList(props, 1), ...children), x, y);
}

/**
 * Generate <a class="card-footer-item"></a>
 */
export function Footer_a(x, y) {
    return Footer_itemView((props, children) => react.createElement("a", keyValueList(props, 1), ...children), x, y);
}

