import { toString, Union, Record } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { AnonymousService_RouteBuilder, Request_ExternalAuthScheme, Request_Login, Response_UserInfo_$reflection, Request_Login_$reflection } from "../Omnicv.Shared/API.js";
import { union_type, class_type, record_type, string_type, bool_type, option_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { anonService } from "../Omnicv.Client.Common/CommonServer.js";
import { RouteModule_toHash, Route, Router_newUrl } from "../Omnicv.Client.Common/Router.js";
import { exnToDisplayString } from "../Omnicv.Client.Common/Utils.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { FlexFormBase$1__Render_2B595, FlexFormBase$1__SetErrorOpt_6DFDD678, FlexForm$1__get_Fields, FlexFormFields$1__AddText_Z44011C05, FlexFormFields$1__AddPassword_Z44011C05, noValidation, FlexFormFields$1__AddCheckbox_264AA22A, FlexForm$1_$ctor_2ABDDB55 } from "../Omnicv.Client.Common/Components/FlexForm.js";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.24.0/Seq.js";
import { Config_variable } from "../Omnicv.Client.Common/Extensions.js";
import { Option, button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Size_ISize, Color_IColor } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { singleton as singleton_1, empty, ofArray } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { icon } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { createElement } from "react";
import { reactApi } from "../fable_modules/Feliz.2.9.0/Interop.fs.js";
import { h2 } from "../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";

export class Model extends Record {
    constructor(Request, Response, IsLoading, Error$) {
        super();
        this.Request = Request;
        this.Response = Response;
        this.IsLoading = IsLoading;
        this.Error = Error$;
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Pages.Login.Component.Model", [], Model, () => [["Request", Request_Login_$reflection()], ["Response", option_type(Response_UserInfo_$reflection())], ["IsLoading", bool_type], ["Error", option_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RecordUpdated", "LogInSubmitted", "LogInSucceeded", "LogInFailed"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Login.Component.Msg", [], Msg, () => [[["Item", Request_Login_$reflection()]], [], [["Item", Response_UserInfo_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export function init() {
    return [new Model(new Request_Login("", "", false), undefined, false, undefined), Cmd_none()];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1:
            return [new Model(model.Request, model.Response, true, model.Error), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, anonService.Login, model.Request, (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])))];
        case 2:
            return [new Model(model.Request, msg.fields[0], false, model.Error), Router_newUrl(new Route(0, []))];
        case 3:
            return [new Model(model.Request, undefined, false, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default:
            return [new Model(msg.fields[0], model.Response, model.IsLoading, model.Error), Cmd_none()];
    }
}

function _nonEmpty(query) {
    if (isNullOrWhiteSpace(query) ? true : (query.trim().length < 3)) {
        return "Search query too short";
    }
    else {
        return undefined;
    }
}

function viewForm(model, dispatch) {
    const form = FlexForm$1_$ctor_2ABDDB55((arg) => {
        dispatch(new Msg(0, [arg]));
    }, (_arg) => {
        dispatch(new Msg(1, []));
    }, undefined);
    FlexFormFields$1__AddCheckbox_264AA22A(FlexFormFields$1__AddPassword_Z44011C05(FlexFormFields$1__AddText_Z44011C05(FlexForm$1__get_Fields(form), "Username", _nonEmpty, "User name"), "Password", _nonEmpty, "Password"), "IsPersistent", noValidation, "Remember me?");
    FlexFormBase$1__SetErrorOpt_6DFDD678(form, model.Error);
    return FlexFormBase$1__Render_2B595(form, model.Request);
}

export function view(model, dispatch) {
    if (model.Response != null) {
        return "Already logged in";
    }
    else {
        const children_2 = toList(delay(() => append(!(Config_variable("NO_GOOGLE_AUTH") === "true") ? append(singleton(button(ofArray([new Option(0, [new Color_IColor(3, [])]), new Option(16, [false]), new Option(18, [(_arg) => {
            const scheme = toString(new Request_ExternalAuthScheme());
            const location = window.location;
            const baseUrl = `${location.protocol}//${location.hostname}:${location.port}`;
            const path_2 = AnonymousService_RouteBuilder("", `LoginExt?scheme=${scheme}&next=${encodeURIComponent(`${baseUrl}${AnonymousService_RouteBuilder("", `OnLoginExt?final=${`${baseUrl}${RouteModule_toHash(new Route(4, []))}`}`)}`)}`);
            location.href = (`${baseUrl}${path_2}`);
        }]), new Option(1, [new Size_ISize(2, [])])]), ofArray([icon(empty(), singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, ["fab fa-google"])), []))), createElement("span", {
            children: reactApi.Children.toArray(["Login with Google"]),
        })]))), delay(() => append(singleton(createElement("hr", {})), delay(() => singleton(h2(empty())(singleton_1("Login with password"))))))) : empty_1(), delay(() => singleton(viewForm(model, dispatch))))));
        return createElement("div", {
            children: reactApi.Children.toArray(Array.from(children_2)),
        });
    }
}

